export const navServiceiItems = [
    {
        "title": "Domowy hotel dla psa",
        "link": "https://zoocial.pl/pethomer/b/nasze-uslugi/domowy-hotel-dla-psow/",
        "subMenu": [
            {
                "title": "Psi Hotel Warszawa",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-warszawa/"
            },
            {
                "title": "Psi Hotel Wrocław",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-wroclaw/"
            },
            {
                "title": "Psi Hotel Gdańsk",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-gdansk/"
            },
            {
                "title": "Psi Hotel Poznań",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-poznan/"
            },
            {
                "title": "Psi Hotel Gdynia",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-gdynia/"
            },
            {
                "title": "Psi Hotel Katowice",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-katowice/"
            },
            {
                "title": "Psi Hotel Kraków",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-krakow/"
            },
            {
                "title": "Psi Hotel Lublin",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-lublin/"
            },
            {
                "title": "Psi Hotel Łódź",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-lodz/"
            },
            {
                "title": "Psi Hotel Olsztyn",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-olsztyn/"
            },
            {
                "title": "Psi Hotel Opole",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-opole/"
            },
            {
                "title": "Psi Hotel Płock",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-plock/"
            },
            {
                "title": "Psi Hotel Rzeszów",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-rzeszow/"
            },
            {
                "title": "Psi Hotel Sopot",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-sopot/"
            },
            {
                "title": "Psi Hotel Szczecin",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-szczecin/"
            },
            {
                "title": "Psi Hotel Toruń",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-torun/"
            },
            {
                "title": "Psi Hotel Trójmiasto",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-trojmiasto/"
            },
            {
                "title": "Psi Hotel Białystok",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-bialystok/"
            },
            {
                "title": "Psi Hotel Bydgoszcz",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-bydgoszcz/"
            },
            {
                "title": "Psi Hotel Dąbrowa Górnicza",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-dabrowa-gornicza/"
            },
            {
                "title": "Psi Hotel Gliwice",
                "link": "https://zoocial.pl/pethomer/b/hotel-dla-psow-gliwice/"
            }
        ]
    },
    {
        "title": "Spacer z psem",
        "link": "https://zoocial.pl/pethomer/b/nasze-uslugi/wyprowadzanie-psow/",
        "subMenu": [
            {
                "title": "Spacer z psem Warszawa",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-warszawa/"
            },
            {
                "title": "Spacer z psem Wrocław",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-wroclaw/"
            },
            {
                "title": "Spacer z psem Gdańsk",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-gdansk/"
            },
            {
                "title": "Spacer z psem Poznań",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-poznan/"
            },
            {
                "title": "Spacer z psem Gdynia",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-gdynia/"
            },
            {
                "title": "Spacer z psem Katowice",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-katowice/"
            },
            {
                "title": "Spacer z psem Kraków",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-krakow/"
            },
            {
                "title": "Spacer z psem Lublin",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-lublin/"
            },
            {
                "title": "Spacer z psem Łódź",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-lodz/"
            },
            {
                "title": "Spacer z psem Olsztyn",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-olsztyn/"
            },
            {
                "title": "Spacer z psem Opole",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-opole/"
            },
            {
                "title": "Spacer z psem Płock",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-plock/"
            },
            {
                "title": "Spacer z psem Rzeszów",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-rzeszow/"
            },
            {
                "title": "Spacer z psem Sopot",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-sopot/"
            },
            {
                "title": "Spacer z psem Szczecin",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-szczecin/"
            },
            {
                "title": "Spacer z psem Toruń",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-torun/"
            },
            {
                "title": "Spacer z psem Trójmiasto",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-trojmiasto/"
            },
            {
                "title": "Spacer z psem Białystok",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-bialystok/"
            },
            {
                "title": "Spacer z psem Bydgoszcz",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-bydgoszcz/"
            },
            {
                "title": "Spacer z psem Dąbrowa Górnicza",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-dabrowa-gornicza/"
            },
            {
                "title": "Spacer z psem Gliwice",
                "link": "https://zoocial.pl/pethomer/b/wyprowadzanie-psow-gliwice/"
            }
        ]
    },
    {
        "title": "Karmienie",
        "link": "https://zoocial.pl/pethomer/b/nasze-uslugi/karmienie-opieka/",
        "subMenu": [
            {
                "title": "Karmienie Opieka Warszawa",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-warszawa/"
            },
            {
                "title": "Karmienie Opieka Wrocław",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-wroclaw/"
            },
            {
                "title": "Karmienie Opieka Poznań",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-poznan/"
            },
            {
                "title": "Karmienie Opieka Gdańsk",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-gdansk/"
            },
            {
                "title": "Karmienie Opieka Gdynia",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-gdynia/"
            },
            {
                "title": "Karmienie Opieka Katowice",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-katowice/"
            },
            {
                "title": "Karmienie Opieka Kraków",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-krakow/"
            },
            {
                "title": "Karmienie Opieka Łódź",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-lodz/"
            },
            {
                "title": "Karmienie Opieka Gliwice",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-gliwice/"
            },
            {
                "title": "Karmienie Opieka Lublin",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-lublin/"
            },
            {
                "title": "Karmienie Opieka Olsztyn",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-olsztyn/"
            },
            {
                "title": "Karmienie Opieka Opole",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-opole/"
            },
            {
                "title": "Karmienie Opieka Płock",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-plock/"
            },
            {
                "title": "Karmienie Opieka Rzeszów",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-rzeszow/"
            },
            {
                "title": "Karmienie Opieka Sopot",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-sopot/"
            },
            {
                "title": "Karmienie Opieka Szczecin",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-szczecin/"
            },
            {
                "title": "Karmienie Opieka Toruń",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-torun/"
            },
            {
                "title": "Karmienie Opieka Trójmiasto",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-trojmiasto/"
            },
            {
                "title": "Karmienie Opieka Białystok",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-bialystok/"
            },
            {
                "title": "Karmienie Opieka Bydgoszcz",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-bydgoszcz/"
            },
            {
                "title": "Karmienie Opieka Dąbrowa Górnicza",
                "link": "https://zoocial.pl/pethomer/b/karmienie-opieka-dabrowa-gornicza/"
            }
        ]
    },
    {
        "title": "Opieka dzienna",
        "link": "https://zoocial.pl/pethomer/b/nasze-uslugi/opieka-nad-psem/",
        "subMenu": [
            {
                "title": "Opieka nad psem Warszawa",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-warszawa/"
            },
            {
                "title": "Opieka nad psem Wrocław",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-wroclaw/"
            },
            {
                "title": "Opieka nad psem Gdańsk",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-gdansk/"
            },
            {
                "title": "Opieka nad psem Poznań",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-poznan/"
            },
            {
                "title": "Opieka nad psem Gdynia",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-gdynia/"
            },
            {
                "title": "Opieka nad psem Katowice",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-katowice/"
            },
            {
                "title": "Opieka nad psem Kraków",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-krakow/"
            },
            {
                "title": "Opieka nad psem Lublin",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-lublin/"
            },
            {
                "title": "Opieka nad psem Łódź",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-lodz/"
            },
            {
                "title": "Opieka nad psem Olsztyn",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-olsztyn/"
            },
            {
                "title": "Opieka nad psem Opole",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-opole/"
            },
            {
                "title": "Opieka nad psem Płock",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-plock/"
            },
            {
                "title": "Opieka nad psem Rzeszów",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-rzeszow/"
            },
            {
                "title": "Opieka nad psem Sopot",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-sopot/"
            },
            {
                "title": "Opieka nad psem Szczecin",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-szczecin/"
            },
            {
                "title": "Opieka nad psem Toruń",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-torun/"
            },
            {
                "title": "Opieka nad psem Trójmiasto",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-trojmiasto/"
            },
            {
                "title": "Opieka nad psem Białystok",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-bialystok/"
            },
            {
                "title": "Opieka nad psem Bydgoszcz",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-bydgoszcz/"
            },
            {
                "title": "Opieka nad psem Dąbrowa Górnicza",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-dabrowa-gornicza/"
            },
            {
                "title": "Opieka nad psem Gliwice",
                "link": "https://zoocial.pl/pethomer/b/opieka-nad-psem-gliwice/"
            }
        ]
    }
]