import React from 'react';
import { injectIntl } from '../../util/reactIntl';

import css from './SimpleBreadcrumbs.module.css';


const SimpleBreadcrumbsComponent = props => {
  return <div className={css.root}>
    {props.stepNo && <div className={css.stepContainer}>
      Krok {props.stepNo}.
    </div>}

    <div className={css.header}>
      {props.children}
    </div>
  </div>
};



const SimpleBreadcrumbs = injectIntl(SimpleBreadcrumbsComponent);

export default SimpleBreadcrumbs;
