import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';
import { array, arrayOf, bool, func, string } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FieldCheckboxGroup, FieldDateRangeInput, Form, IconSpinner, PrimaryButton } from '../../components';
import FieldTextInput from '../../components/FieldTextInput/FieldTextInput';
import config from '../../config';
import { updateBookingStatusFromEnquiryListing } from '../../util/api';
import { END_DATE, START_DATE } from '../../util/dates';
import { pushEvent } from '../../util/pushEvent';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { bookingDatesRequired, composeValidators, oneSlotRequired, required } from '../../util/validators';
import css from './BookingDatesForm.module.css';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';

const identity = v => v;

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      formValues: null,
      initialValues: props.initialValues,
      initialBookingDataForVendor: null,
      isBookingEnquiryAccepted: false,
      customerName: null,
      isSubmitting: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.acceptBookingHandler = this.acceptBookingHandler.bind(this);
  }

  componentDidMount() {
    var bookingDates = null,
      bookingData = null,
      bookingServiceType = null,
      bookingPreference = null,
      bookingTimeSlot = null;
    var bookingStart = null,
      bookingEnd = null,
      values = null;

    if (this.props.isFromEnquiryForVendor) {
      const { attributes } = this.props.transaction && this.props.transaction.customer;
      const { profile } = attributes;
      const { enquiryListing } = profile && profile.publicData;

      this.setState({ customerName: profile && profile.displayName });

      if (enquiryListing) {
        values = enquiryListing.find(x => x.transactionId === this.props.transaction.id.uuid);
        if (values) {
          var { bookingPreference, bookingServiceType, bookingTimeSlot, enquiryStatus } = values;
          bookingPreference = values.bookingPreference;
          bookingDates = values.bookingDates && JSON.parse(values.bookingDates);
          bookingStart = bookingDates && bookingDates.startDate;
          bookingEnd = bookingDates && bookingDates.endDate;
          this.setState({
            initialBookingDataForVendor: {
              bookingDates,
              bookingPreference,
              bookingServiceType,
              bookingTimeSlot,
              enquiryStatus,
            },
            isBookingEnquiryAccepted: enquiryListing.isAccepted ? enquiryListing.isAccepted : false,
          });
        }
      }
    }

    if (values) {
      this.setState({ formValues: values });
      if (bookingDates && bookingServiceType) {
        const serviceType = bookingServiceType;
        const listingId = this.props.listingId;
        if (bookingStart && bookingEnd && serviceType && !this.props.fetchLineItemsInProgress) {
          this.props.onFetchTransactionLineItems({
            bookingData: {
              startDate: moment(bookingStart).toDate(),
              endDate: moment(bookingEnd).toDate(),
              serviceType,
              bookingPreference,
              bookingTimeSlot,
              enquiryStatus: enquiryStatus ? enquiryStatus : null,
            },
            listingId,
            isOwnListing: this.props.isOwnListing,
          });
        }
      }
    }
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    this.setState({ isSubmitting: true });
    if (this.props.initialValues) {
      // if this data from submitted enquiry we don't have to get the value from the form because there is initialValues
      const { bookingDates } = this.props.initialValues;
      const { bookingStart, bookingEnd } = bookingDates;
      const bookingData = {
        ...this.props.initialValues.bookingData,
        bookingDates: {
          startDate: moment(bookingStart).toDate(),
          endDate: moment(bookingEnd).toDate(),
        },
      };
      this.props.onSubmit(bookingData);
    } else {
      const { startDate, endDate, bookingServiceType, bookingPreference } = e.bookingDates || {};
      if (!startDate) {
        e.preventDefault();
        this.setState({ focusedInput: START_DATE });
      } else if (!endDate) {
        e.preventDefault();
        this.setState({ focusedInput: END_DATE });
      } else {
        this.props.onSubmit(e);
      }
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { values } = formValues;
    this.setState({ formValues: values });

    if (values) {
      const { bookingDates, bookingServiceType, bookingPreference, bookingTimeSlot } = values;

      if (bookingDates && bookingServiceType) {
        const { startDate, endDate } = bookingDates;
        const serviceType = bookingServiceType ? bookingServiceType : null;
        const listingId = this.props.listingId;
        const isOwnListing = this.props.isOwnListing;
        const canFetchLineItems = (serviceType === 'feeding' || serviceType === 'walking') ? bookingTimeSlot && bookingTimeSlot.length : true;

        if (startDate && endDate && serviceType && !this.props.fetchLineItemsInProgress && canFetchLineItems) {
          this.props.onFetchTransactionLineItems({
            bookingData: { startDate, endDate, serviceType, bookingPreference, bookingTimeSlot },
            listingId,
            isOwnListing,
          });
        }
      }
    }
  }

  acceptBookingHandler(status, bookingData, lineItems) {
    const { listing, transaction } = this.props;
    const { id, customer } = transaction;
    const { profile } = customer && customer.attributes;
    const { enquiryListing } = profile && profile.publicData;
    const customerId = customer && customer.id && customer.id.uuid;
    const transactionId = id && id.uuid;

    var _enquiryListing = enquiryListing.filter(x => x.transactionId !== transactionId);
    var booking = enquiryListing.find(x => x.transactionId === transactionId);
    _enquiryListing = [
      ..._enquiryListing,
      {
        ...booking,
        enquiryStatus: status,
      },
    ];
    var provider = 'vendor';
    let message;
    if (this.props.currentProvider)
      provider = this.props.currentProvider.attributes.profile.displayName;
    if (status == 'accepted') {
      message = `WIADOMOŚĆ OD PETHOMER: ${provider} wstępnie zaakceptował(a) Twoje zapytanie. Opłać usługę jak najszybciej aby mieć pewność zarezerwowanego terminu.`;
    } else {
      message = `WIADOMOŚĆ OD PETHOMER: ${provider} nie zaakceptował Twojego zapytania. Nie przejmuj się, pewnie planuje jakieś fajne wakacje  Po prostu znajdź innego opiekuna.`;
    }
    this.props.onMessageSubmit(message, this.props.currentProvider);
    updateBookingStatusFromEnquiryListing({
      publicData: {
        enquiryListing: _enquiryListing,
      },
      customerId: customerId,
    }).then(res => {
      pushEvent(status === "accepted" ? 'reservation_accepted' : "reservation_rejected", {
        reservation_id: transactionId,
        petsitter_user_id: transaction.provider.id.uuid,
        pet_owner_id: customerId,
        pet_size: bookingData?.bookingPreference,
        petsitter_service: bookingData?.serviceType,
        petsitter_location: listing?.attributes?.publicData?.location?.address,
        petsitter_start_date: bookingData?.startDate,
        petsitter_end_date: bookingData?.endDate,
        petsitter_service_quantity: lineItems?.length || 0,
      });
      window.location.reload();
    });
  }

  renderButton(
    isOwnListing,
    initialValues,
    bookingData,
    submitButtonClasses,
    acceptBookingHandler,
    lineItems
  ) {
    if (!isOwnListing) {
      if (initialValues && bookingData && bookingData.enquiryStatus) {
        if (bookingData.enquiryStatus === 'accepted') {
          return (
            <div className={submitButtonClasses}>
              <PrimaryButton type="submit" inProgress={this.state.isSubmitting}>
                <FormattedMessage id="BookingDatesForm.makePayment" />
              </PrimaryButton>
            </div>
          );
        }
      } else {
        return (
          <div className={submitButtonClasses}>
            <PrimaryButton type="submit" inProgress={this.state.isSubmitting}>
              <FormattedMessage id="BookingDatesForm.requestToBook" />
            </PrimaryButton>
          </div>
        );
      }
    } else {
      if (bookingData && bookingData.enquiryStatus) {
        if (bookingData.enquiryStatus === 'pending') {
          return (
            <Fragment>
              <div className={submitButtonClasses}>
                <span
                  className={css.primaryButtonRoot}
                  onClick={() => acceptBookingHandler('accepted', bookingData, lineItems)}
                >
                  <FormattedMessage id="BookingDatesForm.acceptBookingEnquiry" />
                </span>
              </div>
              <div className={submitButtonClasses}>
                <span
                  className={css.secondaryButtonRoot}
                  onClick={() => acceptBookingHandler('declined', bookingData, lineItems)}
                >
                  <FormattedMessage id="BookingDatesForm.declineBookingEnquiry" />
                </span>
              </div>
            </Fragment>
          );
        }
      }
    }
  }

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      listing,
      initialValues,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const { publicData } = listing.attributes;
    const { displayName } =
      (listing.author && listing.author.attributes && listing.author.attributes.profile) || {};

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <>
        <FinalForm
          {...rest}
          unitPrice={unitPrice}
          mutators={{ ...arrayMutators }}
          onSubmit={this.handleFormSubmit}
          render={fieldRenderProps => {
            const {
              endDatePlaceholder,
              startDatePlaceholder,
              formId,
              handleSubmit,
              intl,
              isOwnListing,
              submitButtonWrapperClassName,
              unitType,
              values,
              timeSlots,
              fetchTimeSlotsError,
              lineItems,
              fetchLineItemsInProgress,
              fetchLineItemsError,
              onFetchTransactionLineItems,
              isFromEnquiryForVendor,
            } = fieldRenderProps;
            const { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};

            const bookingStartLabel = intl.formatMessage({
              id: 'BookingDatesForm.bookingStartTitle',
            });
            const bookingEndLabel = intl.formatMessage({
              id: 'BookingDatesForm.bookingEndTitle',
            });
            const requiredMessage = intl.formatMessage({
              id: 'BookingDatesForm.requiredDate',
            });
            const startDateErrorMessage = intl.formatMessage({
              id: 'FieldDateRangeInput.invalidStartDate',
            });
            const endDateErrorMessage = intl.formatMessage({
              id: 'FieldDateRangeInput.invalidEndDate',
            });
            const timeSlotsError = fetchTimeSlotsError ? (
              <p className={css.sideBarError}>
                <FormattedMessage id="BookingDatesForm.timeSlotsError" />
              </p>
            ) : null;

            // This is the place to collect breakdown estimation data.
            // Note: lineItems are calculated and fetched from FTW backend
            // so we need to pass only booking data that is needed otherwise
            // If you have added new fields to the form that will affect to pricing,
            // you need to add the values to handleOnChange function
            var bookingData = null;
            if (startDate && endDate) {
              bookingData = {
                unitType,
                startDate,
                endDate,
              };
            } else if (initialValues) {
              // this else need to be removed. will use isFromEnquiryForVendor instead
              const { bookingDates } = initialValues;
              const booking = initialValues.bookingData;
              const {
                bookingServiceType,
                bookingPreference,
                bookingTimeSlot,
                enquiryStatus,
              } = booking;
              if (bookingDates && bookingServiceType) {
                const { bookingStart, bookingEnd } = bookingDates;
                const serviceType = bookingServiceType ? bookingServiceType : null;
                bookingData = {
                  startDate: bookingStart,
                  endDate: bookingEnd,
                  serviceType,
                  bookingPreference,
                  bookingTimeSlot,
                  enquiryStatus,
                };
              }
            } else if (isFromEnquiryForVendor && this.state.initialBookingDataForVendor) {
              const {
                bookingServiceType,
                bookingDates,
                ...restBookingData
              } = this.state.initialBookingDataForVendor;

              if (bookingDates && bookingServiceType) {
                const { startDate, endDate } = bookingDates;
                bookingData = {
                  startDate: moment(startDate).toDate(),
                  endDate: moment(endDate).toDate(),
                  serviceType: bookingServiceType,
                  ...restBookingData,
                };
              }
            } else {
              bookingData = null;
            }

            var showEstimatedBreakdown =
              bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

            const bookingInfoMaybe = showEstimatedBreakdown ? (
              <div className={css.priceBreakdownContainer}>
                {!isFromEnquiryForVendor ? (
                  <h3 className={css.priceBreakdownTitle}>
                    <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
                  </h3>
                ) : null}
                <EstimatedBreakdownMaybe
                  bookingData={bookingData}
                  lineItems={lineItems}
                  bookingServiceType={this.state.formValues && this.state.formValues.bookingServiceType}
                  // bookingServiceType={bookingData && bookingData.serviceType}
                  isFromEnquiryForVendor={isFromEnquiryForVendor}
                />
              </div>
            ) : null;

            const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
              <IconSpinner className={css.spinner} />
            ) : null;

            const bookingInfoErrorMaybe = fetchLineItemsError ? (
              <span className={css.sideBarError}>
                <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
              </span>
            ) : null;

            const dateFormatOptions = {
              weekday: 'short',
              month: 'short',
              day: 'numeric',
            };

            const now = moment();
            const today = now.startOf('day').toDate();
            const tomorrow = now
              .startOf('day')
              .add(1, 'days')
              .toDate();
            const startDatePlaceholderText =
              startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
            const endDatePlaceholderText =
              endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
            const submitButtonClasses = classNames(
              submitButtonWrapperClassName || css.submitButtonWrapper
            );

            const filterConfig = config.custom.filters;
            const timeSlotOptions = findOptionsForSelectFilter('timeSlot', filterConfig);
            const timeSlotSelectedOptions =
              publicData && publicData.timeSlot ? publicData.timeSlot : [];
            var timeSlotSelectedConfigOptions = timeSlotOptions.filter(o =>
              timeSlotSelectedOptions.find(s => s === o.key)
            );

            const bookingTimeSlotLabel = intl.formatMessage({
              id: 'FieldDateTimeInput.bookingTimeSlotLabel',
            });
            // const customerName =

            const messageRequiredMessage = intl.formatMessage({
              id: 'EnquiryForm.messageRequired',
            });

            const slotInvalidMessage = intl.formatMessage({
              id: 'EnquiryForm.slotInvalidMessage',
            })

            let timeSlotLabel = 'Service';
            let messageLabel = 'Message';
            let messagePlaceholder = `Eg. Hi ${displayName}! My dog is a three-year-old Beagle, he is energetic and friendly. He gets along / does not get along with other dogs... (if your dog requires a special approach or skills from the Sitter, please write about it here).`;
            const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);
            if (typeof window !== 'undefined') {
              var language = window.localStorage.getItem('lang');
              if (language != 'EN') {
                timeSlotSelectedConfigOptions = timeSlotSelectedConfigOptions.map(opt => {
                  return {
                    ...opt,
                    label: opt.polishLabel,
                  };
                });
                timeSlotLabel = 'Usługa';
                messageLabel = 'Wiadomość';
                messagePlaceholder = `Np. Cześć ${displayName}! Mój pies to trzyletni Beagle, jest energiczny i przyjazny wobec ludzi. Dogaduje / nie dogaduje się z innymi zwierzętami... (jeżeli twój pies wymaga specjalnego podejścia lub szczególnych umiejętności u Opiekuna, napisz o tym).`;
              }
            }
            return (
              <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
                {timeSlotsError}

                <FormSpy
                  subscription={{ values: true }}
                  onChange={values => {
                    this.handleOnChange(values);
                  }}
                />
                {!isFromEnquiryForVendor ? (
                  <FieldDateRangeInput
                    className={css.bookingDates}
                    name="bookingDates"
                    unitType={this.state.formValues && this.state.formValues.bookingServiceType === 'boarding' ? 'line-item/night' : 'line-item/day'}
                    startDateId={`${formId}.bookingStartDate`}
                    startDateLabel={bookingStartLabel}
                    startDatePlaceholderText={startDatePlaceholderText}
                    endDateId={`${formId}.bookingEndDate`}
                    endDateLabel={bookingEndLabel}
                    endDatePlaceholderText={endDatePlaceholderText}
                    focusedInput={this.state.focusedInput}
                    onFocusedInputChange={this.onFocusedInputChange}
                    format={identity}
                    timeSlots={timeSlots}
                    useMobileMargins
                    validate={
                      initialValues
                        ? null
                        : composeValidators(
                          required(requiredMessage),
                          bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
                        )
                    }
                    // disabled={fetchLineItemsInProgress}
                    disabled={initialValues ? true : fetchLineItemsInProgress}
                    listing={listing}
                    intl={intl}
                    initialValues={initialValues}
                  />
                ) : null}

                {this.state.formValues && !isFromEnquiryForVendor &&
                  (this.state.formValues.bookingServiceType === 'feeding' ||
                    this.state.formValues.bookingServiceType === 'walking') && (
                    <div>
                      <div className={css.formRow}>
                        <div className={css.fieldSelectInput}>
                          <label>Wybierz przedziały czasowe</label>
                          {
                            <FieldCheckboxGroup
                              name="bookingTimeSlot"
                              id="bookingTimeSlot"
                              options={timeSlotSelectedConfigOptions}
                              validate={oneSlotRequired(slotInvalidMessage)}
                            />
                          }
                          {/* <FieldSelect
                          name="bookingTimeSlot"
                          id="bookingTimeSlot"
                          className={css.serviceFieldSelect}
                          selectClassName={css.select}
                          label={bookingTimeSlotLabel}
                          disabled={initialValues ? true : false}
                          required={true}
                          initialValue={
                            initialValues &&
                            initialValues.bookingData &&
                            initialValues.bookingData.bookingTimeSlot
                          }
                        >
                          {timeSlotSelectedConfigOptions && timeSlotSelectedConfigOptions.length ? (
                            <Fragment>
                              <option></option>
                              {timeSlotSelectedConfigOptions.map(opt => (
                                <option key={opt.key} value={opt.key}>
                                  {opt.label}
                                </option>
                              ))}
                            </Fragment>
                          ) : (
                            <option>{timeSlotLabel}</option>
                          )}
                        </FieldSelect> */}
                        </div>
                      </div>
                    </div>
                  )}
                {/* this message is compulsory because the sdk needs a message in order to send an enquiry */}
                {/* no need to show this if this page came from enquiry */}
                {isFromEnquiryForVendor ? null : (
                  <FieldTextInput
                    className={css.field}
                    style={{ marginBottom: 20 }}
                    type="textarea"
                    name="message"
                    id="bookingMessage"
                    label={messageLabel}
                    placeholder={messagePlaceholder}
                    validate={messageRequired}
                  />
                )}
                {bookingInfoMaybe}
                {loadingSpinnerMaybe}
                {bookingInfoErrorMaybe}

                <p className={css.smallPrint}>
                  {/* <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                /> */}
                </p>
                {this.renderButton(
                  isOwnListing,
                  initialValues,
                  bookingData,
                  submitButtonClasses,
                  this.acceptBookingHandler,
                  lineItems
                )}
              </Form>
            );
          }}
        />
      </>
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  initialValues: null,
  isFromEnquiryForVendor: false,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const mapDispatchToProps = dispatch => ({
  // onUpdateBookingStatus: data => dispatch(updateBookingStatusFromEnquiryListing(data)),
});

const BookingDatesForm = compose(
  connect(mapDispatchToProps),
  injectIntl
)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
