import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import ReviewRating from '../ReviewRating/ReviewRating';
import Avatar from '../Avatar/Avatar';
import ClockImg from './clock.png';
import { pushEvent } from '../../util/pushEvent';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const { className, rootClassName, intl, listing, renderSizes, setActiveListing, search, index } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  var selectedLang = 'PL';
  if (typeof window !== 'undefined') {
    selectedLang = window.localStorage.getItem('lang')
      ? window.localStorage.getItem('lang')
      : selectedLang;
  }

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
      ? 'ListingCard.perDay'
      : 'ListingCard.perUnit';

  let priceDisplay = null;
  switch (search && search.pub_amenities) {
    case 'has_all:boarding':
      priceDisplay = {
        unit: 'night',
        unitPolish: 'za dobę',
        value:
          listing.attributes.publicData &&
          listing.attributes.publicData.serviceFee.find(({ key }) => key == 'serviceFee_boarding'),
      };
      break;
    case 'has_all:walking':
      priceDisplay = {
        unit: 'walk',
        unitPolish: 'za spacer',
        value:
          listing.attributes.publicData &&
          listing.attributes.publicData.serviceFee.find(({ key }) => key == 'serviceFee_walking'),
      };
      break;
    case 'has_all:feeding':
      priceDisplay = {
        unit: 'feed',
        unitPolish: 'za karmienie',
        value:
          listing.attributes.publicData &&
          listing.attributes.publicData.serviceFee.find(({ key }) => key == 'serviceFee_feeding'),
      };
      break;
    case 'has_all:daycare':
      priceDisplay = {
        unit: 'day',
        unitPolish: 'za dzień',
        value:
          listing.attributes.publicData &&
          listing.attributes.publicData.serviceFee.find(({ key }) => key == 'serviceFee_daycare'),
      };
      break;
  }

  const publicData = listing && listing.attributes && listing.attributes.publicData;
  const { averageResponseTime } = listing?.attributes?.metadata || {};
  const { ratingScore, ratingCount } = publicData;
  // ratingScore/10 because it get saved as ratingScore*10 (need to get the decimal form)
  const listingRating = ratingScore ? (ratingScore / 10).toFixed(1) : 0;

  const responseTimeValue = (averageResponseTime) => {
    if (averageResponseTime <= 30) return 'ListingCard.30m';
    if (averageResponseTime <= 60 * 3) return 'ListingCard.3h';
    if (averageResponseTime <= 60 * 6) return 'ListingCard.6h';
    if (averageResponseTime <= 60 * 12) return 'ListingCard.12h';
    if (averageResponseTime <= 60 * 24) return 'ListingCard.24h';
    if (averageResponseTime <= 60 * 36) return 'ListingCard.12h';
    if (averageResponseTime <= 60 * 48) return 'ListingCard.48h';
    return 'ListingCard.above48h';
  }

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}
      onClick={() => {
        pushEvent('select_item', {
          item: {
            item_id: id,
            item_name: title,
            item_location: currentListing?.attributes?.publicData?.location?.address,
            item_variant: currentListing?.type,
            price: currentListing?.attributes?.price?.amount, 
            item_rating: currentListing?.attributes?.publicData?.ratingScore,
            item_response_time: currentListing?.attributes?.metadata?.averageResponseTime,
            index: index,
            item_list_name: authorName
          }
        });
      }}
    >
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
        {priceDisplay && priceDisplay.value && (
          <div className={css.priceWrapper}>
            <div className={css.priceContainer}>
              <span className={css.price}>
                {priceDisplay.value.amount / 100} {priceDisplay.value.currency}
              </span>
              <span className={css.unit}>
                {selectedLang && selectedLang === 'PL'
                  ? priceDisplay.unitPolish
                  : 'per ' + priceDisplay.unit}
              </span>
            </div>
          </div>
        )}
        <div className={css.shadowOverlay}>
          <div className={css.info}>
            {/* <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
         </div> */}
            <div className={css.mainInfo}>
              <div className={css.authorInfo}>
                <FormattedMessage id="ListingCard.hostedBy" values={{ authorName }} />
              </div>
              {listingRating > 0 ? (
                <div className={css.reviewWrapper}>
                  <ReviewRating
                    // add rating dynamically once logic is done
                    rating={listingRating}
                  />
                  <span className={css.review}>{listingRating}</span>
                  <div className={css.reviewsCount}>
                    {!!ratingCount && <FormattedMessage id="ListingCard.reviewsCount" values={{ count: ratingCount }} />}
                  </div>
                </div>
              ) : null}
              {
                !!averageResponseTime && averageResponseTime <= 24 * 60 && (
                  <div className={css.responseTime}>
                    <img src={ClockImg} /><FormattedMessage id="ListingCard.responseTime" values={{ time: intl.formatMessage({ id: responseTimeValue(averageResponseTime) }) }} />
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <div className={css.profileImageWrapper}>
          {listing.author.profileImage && (
            <Avatar className={css.avatar} user={listing.author} disableProfileLink={true} />
          )}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
