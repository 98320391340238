function getAccessToken() {
    return localStorage.getItem("accessToken");
}

function withHost(path) {
    return `${process.env.REACT_APP_ADMIN_API_URL}${path}`;
}

async function fetchRequest(method, url, options = {}) {
    const headers = {
        "Content-Type": "application/json",
    };

    const config = {
        method,
        headers,
        body: options.body ? JSON.stringify(options.body) : undefined,
    };

    const response = await fetch(withHost(url), config);
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
}

export const apiClient = {
    get: (url, options) => fetchRequest("GET", url, options),
    post: (url, options) => fetchRequest("POST", url, options),
    put: (url, options) => fetchRequest("PUT", url, options),
    patch: (url, options) => fetchRequest("PATCH", url, options),
    delete: (url, options) => fetchRequest("DELETE", url, options),
};
