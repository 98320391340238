
export const expandLatLngBounds = (bounds, maxZoom = 11) => {
    // Obliczenie szerokości i wysokości obszaru
    const latDiff = bounds.ne.lat - bounds.sw.lat;
    const lngDiff = bounds.ne.lng - bounds.sw.lng;

    // Obecny zoom (przybliżona wartość)
    const currentZoom = Math.log2(360 / Math.max(latDiff, lngDiff));

    if (currentZoom <= maxZoom) {
        return bounds;
    }

    // Obliczenie poprawnego współczynnika skalowania
    const factor = 2 ** (currentZoom - maxZoom);

    // Obliczenie środka prostokąta
    const centerLat = (bounds.ne.lat + bounds.sw.lat) / 2;
    const centerLng = (bounds.ne.lng + bounds.sw.lng) / 2;

    // Obliczenie nowych odległości od środka
    const newLatDelta = (latDiff / 2) * factor;
    const newLngDelta = (lngDiff / 2) * factor;

    bounds.ne.lat = centerLat + newLatDelta;
    bounds.ne.lng = centerLng + newLngDelta;
    bounds.sw.lat = centerLat - newLatDelta;
    bounds.sw.lng = centerLng - newLngDelta;

    return bounds;
}