import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { propTypes } from '../../util/types';
import css from './NavBar.module.css';
import { FormattedMessage } from 'react-intl';

const NavBar = props => {
  const {
    rootClassName,
    className,
  } = props;


  return (
    <>
      <iframe src="https://navbar.zoocial.pl/?website=pethomer" className={classNames(css.navbarRoot, rootClassName, className)}/>
    </>
  );
};


NavBar.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  authScopes: [],
  currentPage: null,
};

const { array, bool, func, string } = PropTypes;

NavBar.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  currentUser: propTypes.currentUser,
  onLogout: func.isRequired,
  currentPage: string,
};

export default NavBar;
