import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaInstagram.module.css';

const IconSocialMediaInstagram = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <path
        d="M21.9969 1H9.00278C4.59005 1 1 4.59022 1 9.00295V21.997C1 26.41 4.59005 30 9.00278 30H21.9969C26.41 30 30 26.4098 30 21.997V9.00295C30.0002 4.59022 26.41 1 21.9969 1ZM27.4272 21.997C27.4272 24.9912 24.9912 27.427 21.997 27.427H9.00278C6.00881 27.4272 3.57302 24.9912 3.57302 21.997V9.00295C3.57302 6.00898 6.00881 3.57302 9.00278 3.57302H21.9969C24.991 3.57302 27.427 6.00898 27.427 9.00295L27.4272 21.997Z"
        fill="var(--secondary-500)"
      />
      <path
        d="M15.5 8.02778C11.3796 8.02778 8.02743 11.3799 8.02743 15.5003C8.02743 19.6206 11.3796 22.9726 15.5 22.9726C19.6204 22.9726 22.9726 19.6206 22.9726 15.5003C22.9726 11.3799 19.6204 8.02778 15.5 8.02778ZM15.5 20.3994C12.7985 20.3994 10.6005 18.2017 10.6005 15.5002C10.6005 12.7985 12.7983 10.6006 15.5 10.6006C18.2017 10.6006 20.3995 12.7985 20.3995 15.5002C20.3995 18.2017 18.2015 20.3994 15.5 20.3994Z"
        fill="var(--secondary-500)"
      />
      <path
        d="M23.286 5.84603C22.7902 5.84603 22.3032 6.04672 21.9531 6.39837C21.6013 6.7483 21.3991 7.23546 21.3991 7.73291C21.3991 8.22882 21.6015 8.7158 21.9531 9.06745C22.3031 9.41738 22.7902 9.61979 23.286 9.61979C23.7834 9.61979 24.2689 9.41738 24.6205 9.06745C24.9721 8.7158 25.1728 8.22864 25.1728 7.73291C25.1728 7.23546 24.9721 6.7483 24.6205 6.39837C24.2706 6.04672 23.7834 5.84603 23.286 5.84603Z"
        fill="var(--secondary-500)"
      />
    </svg>
  );
};

IconSocialMediaInstagram.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaInstagram.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaInstagram;
