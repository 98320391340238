import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldSelect, FieldCheckbox } from '../../components';
import { countryCodes } from './countryCodes';

import css from './SignupForm.module.css';
import { addSendgridContact } from '../../util/api';

const sliceLocal = numbers => {
  if (numbers.length <= 3) {
    return `${numbers}`;
  }

  if (numbers.length <= 6) {
    return `${numbers.slice(0, 3)} ${numbers.slice(3, 6)}`;
  }

  return `${numbers.slice(0, 3)} ${numbers.slice(3, 6)} ${numbers.slice(6, 9)}`;
};

const format = value => {
  if (!value) {
    return '';
  }

  const numbers = value.replace(/[^\d]/g, '');
  return sliceLocal(numbers);
};

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        values
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      // dob
      const dobLabel = intl.formatMessage({
        id: 'SignupForm.dobLabel',
      });
      const dobPlaceholder = intl.formatMessage({
        id: 'SignupForm.dobPlaceholder',
      });
      const dobRequiredMessage = intl.formatMessage({
        id: 'SignupForm.dobRequired',
      });
      const dobRequired = validators.required(dobRequiredMessage);

      // phone number
      const telNumLabel = intl.formatMessage({
        id: 'SignupForm.telNumLabel',
      });
      const telNumPlaceholder = intl.formatMessage({
        id: 'SignupForm.telNumPlaceholder',
      });
      const telNumRequiredMessage = intl.formatMessage({
        id: 'SignupForm.telNumPlaceholder',
      });
      const telNumRequired = validators.required(telNumRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };

      const termsLink = (
        <a
          className={css.termsLink}
          onClick={(e) => {
            window.open('https://zoocial.pl/pethomer/b/regulamin/', '_blank')
            e.stopPropagation();
            e.preventDefault()
          }}
          role="button"
          tabIndex="0"
          href="https://zoocial.pl/pethomer/b/regulamin/"
        // onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </a>
      );

      const privacyPolicy = (
        <a
          className={css.termsLink}
          role="button"
          tabIndex="1"
          href='https://zoocial.pl/pethomer/b/polityka-prywatnosci/'
          target="_blank"
        >
          <FormattedMessage id="SignupForm.privacyPolicyLinkText" />
        </a>
      );


      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>
            <FieldTextInput
              className={css.dob}
              type="text"
              id={formId ? `${formId}.dob` : 'dob'}
              name="dob"
              autoComplete="dob"
              label={dobLabel}
              placeholder={dobPlaceholder}
            // validate={dobRequired}
            />
            <div className={css.phoneWrapper}>
              <FieldSelect
                className={classNames(css.telNum, css.prefixField)}
                label="Kraj"
                id={`telPrefix`}
                name={`telPrefix`}
              >
                {
                  countryCodes.map(p => (
                    <option key={p.name} value={p.code}>
                      {`(${p.code}) ${p.name}`}
                    </option>
                  ))
                }
              </FieldSelect>
              <FieldTextInput
                className={css.telNum}
                type="text"
                id={formId ? `${formId}.telNum` : 'telNum'}
                name="telNum"
                autoComplete="telNum"
                label={telNumLabel}
                placeholder={telNumPlaceholder}
                validate={telNumRequired}
                format={values.telPrefix && values.telPrefix === '+48' ? format : undefined}
              />
            </div>
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
            />
            <div className={css.newsletterContainer}>
              <FieldCheckbox
                id={formId ? `${formId}.terms` : 'terms'}
                name="terms"
                label={<>
                  <FormattedMessage
                    id="SignupForm.termsAndConditionsAcceptText"
                    values={{ termsLink }}
                  /><span className={css.redStar}>*</span>
                </>}
                value="terms"
                textClassName={css.newsletterText}
                validate={validators.requiredCheckbox("Musisz zaakceptować regulamin")}
              />
              <FieldCheckbox
                id={formId ? `${formId}.newsletter` : 'newsletter'}
                name="newsletter"
                label={<FormattedMessage
                  id="SignupForm.newsletterAcceptText"
                  values={{ termsLink }}
                />}
                value="newsletter"
                textClassName={css.newsletterText}
              />
            </div>
          </div>
          <div className={css.termsText}>
            <FormattedMessage
              id="SignupForm.copyText"
              values={{ privacyPolicy }}
            />
            <br/>
            <FormattedMessage
              id="SignupForm.copyText_2"
            />
          </div>
          <PrimaryButton type="submit" inProgress={submitInProgress} >
            <FormattedMessage id="SignupForm.signUp" />
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false, initialValues: { telPrefix: '+48' } };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
