export const pushEvent = (event, data) => {
    if (typeof window === 'undefined') {
        return
    }
    window.dataLayer = window.dataLayer || [],
    window.dataLayer.push({
        event,
        data
    })
    console.log('EVENT PUSHED:', event, data)
}