import React, { useState } from "react";
import classnames from "classnames";
import css from './NestedMenuMobile.module.css';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronRight } from "@mdi/js";

const NestedMenuMobile = ({ items, children }) => {
  const [openKeys, setOpenKeys] = useState({});

  const toggleMenu = (key) => {
    setOpenKeys((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const renderMenu = (items, parentKey = "") => (
    <ul className={classnames(css.menu, { [css.rootMenu]: !parentKey })}>
      {items.map((item, index) => {
        const key = `${parentKey}${index}`;
        const hasSubMenu = Array.isArray(item.subMenu);
        const isOpen = openKeys[key];

        const liElement = <li
          key={key}
          className={classnames(css.item, { [css.hasSubMenu]: hasSubMenu })}
        >
          <div
            className={css.itemContent}
            onClick={() => hasSubMenu && toggleMenu(key)}
          >
            <span>{item.title}</span>
            {hasSubMenu && (
              <Icon
                path={isOpen ? mdiChevronDown : mdiChevronRight}
                size={1}
                color="black"
              />
            )}
          </div>
          {hasSubMenu && isOpen && (
            <div className={css.subMenuWrapper}>{renderMenu(item.subMenu, `${key}-`)}</div>
          )}
        </li>

        if (hasSubMenu) return liElement

        return (
          <a href={item.link} key={key}>
            {liElement}
          </a>
        );
      })}
    </ul>
  );

  return (
    <div className={css.container}>
      <div className={css.headerWrapper}>
        {children && <div className={css.header}>{children}</div>}
        <div className={css.mainMenu}>{renderMenu(items)}</div>
      </div>
    </div>
  );
};

export default NestedMenuMobile;
