/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration, { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { AvatarLarge, NestedMenuMobile, NotificationBadge } from '../../components';
import {
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  NestedMenu,
  NavBar
} from '../../components';
import { Link } from 'react-router-dom';

import css from './TopbarMobileMenu.module.css';

import { navServiceiItems } from '../../navItems';

const PUBLIC_URL = process.env.PUBLIC_URL || '';

const BLOG_URL = `${PUBLIC_URL}/b`;

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    history,
    intl,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const goToCreateListing = () => {
    const routes = routeConfiguration();
    const createListingPath = pathByRouteName("NewListingPage", routes);
    history.push(`${createListingPath}?signUpType=petsitter`);
  }

  let lang = 'PL';
  if (typeof window !== 'undefined') {
    lang = window.localStorage.getItem('lang');
  }

  function switchLang(lang) {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('lang', lang);
      window.location.reload();
    }
  }

  const langMenu = (
    <Menu>
      <MenuLabel className={css.primaryOutlineButton}>
        <span >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.5 19C14.7309 19 19 14.7306 19 9.5C19 4.26906 14.7306 0 9.5 0C4.2691 0 0 4.2694 0 9.5C0 14.7309 4.2694 19 9.5 19ZM12.3183 17.399C12.8566 16.7112 13.2692 15.8908 13.5791 15.066H15.7673C14.8401 16.1092 13.6575 16.9197 12.3183 17.399ZM16.6036 13.9531H13.939C14.2675 12.7586 14.4602 11.4343 14.5012 10.0566H17.8678C17.774 11.4812 17.323 12.8096 16.6036 13.9531ZM16.6036 5.04688C17.323 6.1904 17.774 7.5188 17.8678 8.94336H14.5012C14.4602 7.56571 14.2675 6.24135 13.939 5.04688H16.6036ZM15.7673 3.93359H13.5791C13.2693 3.10958 12.8567 2.28905 12.3183 1.60101C13.6575 2.08028 14.8401 2.89082 15.7673 3.93359ZM10.0566 1.2085C11.149 1.57874 11.9326 2.87787 12.3808 3.93359H10.0566V1.2085ZM10.0566 5.04688H12.7815C13.1354 6.21871 13.3436 7.54816 13.3875 8.94336H10.0566V5.04688ZM10.0566 10.0563H13.3875C13.3436 11.4518 13.1354 12.7813 12.7815 13.9531H10.0566V10.0563ZM10.0566 15.066H12.3808C11.9319 16.1239 11.1481 17.4216 10.0566 17.7915V15.066ZM3.23275 15.0664H5.42094C5.73073 15.8904 6.14327 16.7109 6.68169 17.399C5.34253 16.9198 4.15992 16.1092 3.23275 15.0664ZM8.94336 17.7915C7.85194 17.4216 7.06819 16.1241 6.61916 15.0664H8.94336V17.7915ZM8.94336 13.9531H6.21853C5.86458 12.7813 5.65636 11.4518 5.61253 10.0566H8.94336V13.9531ZM8.94336 8.94336H5.61253C5.65636 7.54816 5.86458 6.21871 6.21853 5.04688H8.94336V8.94336ZM8.94336 1.2085V3.93359H6.61916C7.06815 2.87605 7.85186 1.57845 8.94336 1.2085ZM6.68165 1.60101C6.14338 2.28883 5.73084 3.10921 5.4209 3.93359H3.23275C4.15992 2.89082 5.34253 2.08024 6.68165 1.60101ZM2.39641 5.0465H5.06101C4.73252 6.24135 4.53981 7.56571 4.49884 8.94336H1.13221C1.22602 7.5188 1.67697 6.1904 2.39641 5.0465ZM1.13221 10.0566H4.49884C4.53981 11.4343 4.73252 12.7586 5.06101 13.9531H2.39641C1.67697 12.8096 1.22602 11.4812 1.13221 10.0566Z"
              fill="currentColor"
            />
          </svg>{' '}
          &nbsp;
          {lang ? lang : 'PL'}
        </span>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfileSettingsPage">
          <Link className={css.langOptionLink} onClick={() => switchLang('PL')}>
            {/* <span className={css.menuItemBorder} /> */}
            PL
          </Link>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <Link className={css.langOptionLink} onClick={() => switchLang('EN')}>
            {/* <span className={css.menuItemBorder} /> */}
            EN
          </Link>
        </MenuItem>
      </MenuContent>
    </Menu>
  );

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.createNewListingLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.createNewListingLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    return (
      <div className={css.root}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '52px',
          }}
        >
          <NamedLink className={css.logoLink} name="LandingPage">
            <Logo
              format="desktop"
              className={css.logo}
            // currentPage={currentPage}
            // alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
            />
          </NamedLink>
          {/* {langMenu} */}
        </div>
        <div className={css.menu}>
          <NestedMenuMobile
            items={navServiceiItems}
          >
            <span
              onClick={() => {
                window.open(`${BLOG_URL}/nasze-uslugi`, '_top');
              }}
              className={css.menuItem}>
              <FormattedMessage id="TopbarDesktop.services" />
            </span>
          </NestedMenuMobile>
          <div
            onClick={() => {
              window.open(`${BLOG_URL}/pomoc`, '_top');
            }}
            style={{ cursor: 'pointer' }}
          >
            <span className={css.menuItem}>
              <FormattedMessage id="TopbarDesktop.help" />
            </span>
          </div>
          <div
            onClick={() => {
              window.open(`${BLOG_URL}/kontakt`, '_top');
            }}
            style={{ cursor: 'pointer' }}
          >
            <span className={css.menuItem}>
              <FormattedMessage id="TopbarDesktop.contact" />
            </span>
          </div>
        </div>
        <div className={css.footer}>
          <button className={css.createNewListingLink} onClick={goToCreateListing}>
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </button>
          {signup}
          {login}
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {
          currentUser &&
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ProfilePage'))}
            name="ProfilePage"
            params={{ id: currentUser.id.uuid }}
          >
            <FormattedMessage id="ManageListingsPage.yourProfile" />
          </NamedLink>
        }
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
      </div>
      {!currentUserHasListings && (
        <div className={css.footer}>
          <button className={css.createNewListingLink} onClick={goToCreateListing}>
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </button>
        </div>
      )}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
