import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

const PUBLIC_URL = process.env.PUBLIC_URL || '';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  // const twitterLink = siteTwitterPage ? (
  //   <ExternalLink
  //     key="linkToTwitter"
  //     href={siteTwitterPage}
  //     className={css.icon}
  //     title={goToTwitter}
  //   >
  //     <IconSocialMediaInstagram />
  //   </ExternalLink>
  // ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [instragramLink, fbLink].filter(v => v != null);
};

const FooterComponent = props => {
  const { rootClassName, className, intl, currentUserHasListings } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.infoLinksWrapper}>
              <div className={css.infoLinks}>
                <p className={css.sectionTitle}>Pethomer</p>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <ExternalLink href={`https://zoocial.pl/Panel%20ekspert%C3%B3w`} name="ExpertsPanel"  className={css.link}>
                      <FormattedMessage id="Footer.expertsPanel" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink href={`${PUBLIC_URL}/nasze-uslugi`} name="NewListingPage" className={css.link}>
                      <FormattedMessage id="Footer.ourservices" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink href={`${PUBLIC_URL}/pomoc`} name="AboutPage" className={css.link}>
                      <FormattedMessage id="Footer.help" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink href={`${PUBLIC_URL}/kontakt`} name="LandingPage" className={css.link}>
                      <FormattedMessage id="Footer.contact" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink href={`https://zoocial.pl/pethomer/b/o-nas/`} name="LandingPage" className={css.link}>
                      <FormattedMessage id="Footer.about" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink
                      href={`https://zoocial.pl/pethomer/b/standardy-opieki-zwierzat-pethomer/`}
                      name="LandingPage"
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.quality" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink
                      href={`https://zoocial.pl/pethomer/b/regulamin/`}
                      name="LandingPage"
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.terms" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink
                      href={`https://zoocial.pl/pethomer/b/polityka-prywatnosci/`}
                      name="LandingPage"
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </ExternalLink>
                  </li>
                </ul>
              </div>
              <div className={css.infoLinks}>
                <p className={css.sectionTitle}>
                  <FormattedMessage id="Footer.services" />
                </p>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <ExternalLink href={`${PUBLIC_URL}`} name="HomePage" className={css.link}>
                      <FormattedMessage id="Footer.petcare" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink
                      href={`${PUBLIC_URL}/s?pub_amenities=has_all%3Adaycare`}
                      name="AboutPage"
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.chaperone" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink href={`${PUBLIC_URL}/b/zostan-opiekunem`} name="LandingPage" className={css.link}>
                      <FormattedMessage id="Footer.guardian" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink
                      href={`${PUBLIC_URL}/b/program-ochrony-pethomer`}
                      name="AboutPage"
                      to={{ hash: '#contact' }}
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.protectionProgram" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink
                      href={`${PUBLIC_URL}/b/program-partnerski`}
                      name="AboutPage"
                      to={{ hash: '#contact' }}
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.partnerProgram" />
                    </ExternalLink>
                  </li>
                </ul>
              </div>
              <div className={css.divider}></div>
            </div>

            <div className={css.searches}>
              <p className={css.sectionTitle}>
                <FormattedMessage id="Footer.warsaw" />
              </p>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/hotel-dla-psow-warszawa/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.warsawBoarding" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/wyprowadzanie-psow-warszawa/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.warsawWalk" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/karmienie-opieka-warszawa/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.warsawFeed" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/opieka-nad-psem-warszawa/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.warsawDayCare" />
                  </ExternalLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <p className={css.sectionTitle}>
                <FormattedMessage id="Footer.wroclaw" />
              </p>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/hotel-dla-psow-wroclaw/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.wroclawBoarding" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/wyprowadzanie-psow-wroclaw/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.wroclawWalk" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/karmienie-opieka-wroclaw/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.wroclawFeed" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/opieka-nad-psem-wroclaw/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.wroclawDayCare" />
                  </ExternalLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <p className={css.sectionTitle}>
                <FormattedMessage id="Footer.cracow" />
              </p>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/hotel-dla-psow-krakow/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.cracowBoarding" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/wyprowadzanie-psow-krakow/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.cracowWalk" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/karmienie-opieka-krakow/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.cracowFeed" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/opieka-nad-psem-krakow/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.cracowDayCare" />
                  </ExternalLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <p className={css.sectionTitle}>
                <FormattedMessage id="Footer.gdansk" />
              </p>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/hotel-dla-psow-gdansk/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.gdanskBoarding" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/wyprowadzanie-psow-gdansk/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.gdanskWalk" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/karmienie-opieka-gdansk/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.gdanskFeed" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    href={`${PUBLIC_URL}/b/opieka-nad-psem-gdansk/`}
                    name="SearchPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.gdanskDayCare" />
                  </ExternalLink>
                </li>
              </ul>
            </div>
            <div className={css.someLinks}>{socialMediaLinks}</div>
          </div>
          {/* <div className={css.copyrightAndTermsMobile}>
            <ExternalLink href="/pomoc" name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </ExternalLink>
            <div className={css.tosAndPrivacyMobile}>
              <ExternalLink href="/pomoc" name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </ExternalLink>
              <ExternalLink href="/pomoc" name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </ExternalLink>
            </div>
          </div> */}
          <div className={css.organization} id="organization">
            <a href={`${PUBLIC_URL}`} name="LandingPage" className={css.logoLink}>
              <Logo format="desktop" className={css.logo} />
            </a>
            <div className={css.organizationInfo}>
              <p className={css.organizationCopyright}>
                <span className={css.copyrightLink}>
                  <FormattedMessage id="Footer.copyright" />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FooterComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

FooterComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserHasListings } = state.user;
  return { currentUserHasListings };
};

const Footer = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(FooterComponent);

export default Footer;
